"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.update_pep_pip_status = void 0;
var firestore_1 = require("firebase/firestore");
var firebase_1 = require("../../../firebase");
var store_1 = require("../../../store");
function update_pep_pip_status(request) {
    return __awaiter(this, void 0, void 0, function () {
        var state, userState, userDocRef, pepPipDocRef, userDocSnapshot, data, updatedUserDocSnapshot, userProfile, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 8, , 9]);
                    state = store_1.redux_store.getState();
                    userState = state.userState;
                    // Log the request data
                    console.log("Request data:", request);
                    // Validate request data
                    if (!request.user_id) {
                        throw new Error("User ID is missing.");
                    }
                    if (!request.pep_confimation) {
                        throw new Error("PEP confirmation data is missing.");
                    }
                    if (typeof request.pep_reason !== "string") {
                        throw new Error("PEP reason must be a string.");
                    }
                    if (request.is_pep !== "Yes" && request.is_pep !== "No") {
                        throw new Error("PEP status must be 'Yes' or 'No'.");
                    }
                    userDocRef = (0, firestore_1.doc)(firebase_1.firestore, "users", request.user_id);
                    console.log("User Document reference:", userDocRef.path);
                    pepPipDocRef = (0, firestore_1.doc)(firebase_1.firestore, "pep_pip_adv_profile", request.user_id);
                    console.log("PEP/PIP Document reference:", pepPipDocRef.path);
                    return [4 /*yield*/, (0, firestore_1.getDoc)(pepPipDocRef)];
                case 1:
                    userDocSnapshot = _a.sent();
                    data = {
                        pep_confimation: {
                            pep_pip: request.pep_confimation.pep_pip,
                            foreign_pep: request.pep_confimation.foreign_pep,
                            domestic_pep: request.pep_confimation.domestic_pep,
                            clause_pep: request.pep_confimation.clause_pep,
                        },
                        pep_reason: request.pep_reason,
                        is_pep: request.is_pep,
                        user_id: userDocRef,
                    };
                    if (!userDocSnapshot.exists()) return [3 /*break*/, 3];
                    // Update Firebase Doc
                    return [4 /*yield*/, (0, firestore_1.updateDoc)(pepPipDocRef, data)];
                case 2:
                    // Update Firebase Doc
                    _a.sent();
                    return [3 /*break*/, 5];
                case 3: 
                // Create Firebase Doc
                return [4 /*yield*/, (0, firestore_1.setDoc)(pepPipDocRef, data)];
                case 4:
                    // Create Firebase Doc
                    _a.sent();
                    _a.label = 5;
                case 5: 
                // Update the pep_completed field in the users collection
                return [4 /*yield*/, (0, firestore_1.updateDoc)(userDocRef, { pep_completed: true })];
                case 6:
                    // Update the pep_completed field in the users collection
                    _a.sent();
                    return [4 /*yield*/, (0, firestore_1.getDoc)(pepPipDocRef)];
                case 7:
                    updatedUserDocSnapshot = _a.sent();
                    if (updatedUserDocSnapshot.exists()) {
                        userProfile = updatedUserDocSnapshot.data();
                        console.log("Document updated/created successfully:", userProfile);
                        return [2 /*return*/, { success: true, message: "PEP/PIP Status Updated", content: __assign({ is_loading: false }, userProfile) }];
                    }
                    else {
                        console.error("User profile not found after update/create.");
                        return [2 /*return*/, { success: false, message: "User profile not found", content: null }];
                    }
                    return [3 /*break*/, 9];
                case 8:
                    error_1 = _a.sent();
                    console.error("Error updating PEP/PIP status:", error_1);
                    return [2 /*return*/, { success: false, message: "An error occurred", content: null }];
                case 9: return [2 /*return*/];
            }
        });
    });
}
exports.update_pep_pip_status = update_pep_pip_status;
