"use strict";
var _a;
var _b, _c, _d, _e;
Object.defineProperty(exports, "__esModule", { value: true });
var AIRTIME_CAT = (_b = process.env.AIRTIME_CATEGORY_ID) !== null && _b !== void 0 ? _b : "";
var CELLPHONE_CAT = (_c = process.env.CELLPHONE_CATEGORY_ID) !== null && _c !== void 0 ? _c : "";
var SOLAR_CAT = (_d = process.env.SOLAR_CATEGORY_ID) !== null && _d !== void 0 ? _d : "";
var HOME_APPLIANCES_CAT = (_e = process.env.HOME_APPLIANCE_CATEGORY_ID) !== null && _e !== void 0 ? _e : "";
var categoryMap = (_a = {},
    _a[AIRTIME_CAT] = "Airtime",
    _a[CELLPHONE_CAT] = "Cellphone",
    _a[SOLAR_CAT] = "Solar",
    _a[HOME_APPLIANCES_CAT] = "HomeAppliances",
    _a);
exports.default = categoryMap;
