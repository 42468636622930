"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmploymentDataPage = void 0;
var react_1 = __importStar(require("react"));
var doshx_controls_web_1 = require("doshx_controls_web");
var page_path_1 = require("../../components/page_path");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var update_advanced_user_details_1 = require("../../../../api/calls/user/update_advanced_user_details");
var main_1 = require("../../../../reducers/slices/main");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var get_user_details_1 = require("../../../../api/calls/user/get_user_details");
var application_1 = require("../../../../api/calls/loan/application");
var functions_1 = require("../../../../constants/functions");
var steps_1 = require("./constants/steps");
var route_names_1 = require("../../../../constants/route_names");
var moment = require("moment");
var EmploymentDataPage = function () {
    var _a;
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var params = (0, react_router_dom_1.useParams)();
    var loan_id = params.loan_id;
    var _b = (0, react_1.useState)(""), employeeNumber = _b[0], setEmployeeNumber = _b[1];
    var _c = (0, react_1.useState)(""), employer = _c[0], setEmployer = _c[1];
    var _d = (0, react_1.useState)(""), workAddress = _d[0], setWorkAddress = _d[1];
    var _e = (0, react_1.useState)(""), supervisor = _e[0], setSupervisor = _e[1];
    var _f = (0, react_1.useState)(""), departmentNumber = _f[0], setDepartmentNumber = _f[1];
    var _g = (0, react_1.useState)({
        phone_number: "",
        country_code: "+264",
        country_abbreviation: "NA",
    }), workNumber = _g[0], setWorkNumber = _g[1];
    var _h = (0, react_1.useState)(), startDate = _h[0], setStartDate = _h[1];
    var _j = (0, react_1.useState)(""), employerMessage = _j[0], setEmployerMessage = _j[1];
    var _k = (0, react_1.useState)(""), workAddressMessage = _k[0], setWorkAddressMessage = _k[1];
    var _l = (0, react_1.useState)(""), supervisorMessage = _l[0], setSupervisorMessage = _l[1];
    var _m = (0, react_1.useState)(""), departmentNumberMessage = _m[0], setDepartmentNumberMessage = _m[1];
    var _o = (0, react_1.useState)(""), workNumberMessage = _o[0], setWorkNumberMessage = _o[1];
    var _p = (0, react_1.useState)(""), employeeNumberMessage = _p[0], setEmployeeNumberMessage = _p[1];
    var _q = (0, react_1.useState)(""), startDateMessage = _q[0], setStartDateMessage = _q[1];
    //
    var _r = (0, react_1.useState)(false), isSaving = _r[0], setIsSaving = _r[1];
    var _s = (0, react_1.useState)(true), isLoadingData = _s[0], setIsLoadingData = _s[1];
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    (0, react_1.useEffect)(function () {
        if (user.uid) {
            (0, get_user_details_1.get_user_details)().then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                var _a, start_date;
                var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
                return __generator(this, function (_s) {
                    switch (_s.label) {
                        case 0:
                            if (!response.success) return [3 /*break*/, 2];
                            setEmployeeNumber((_c = (_b = response === null || response === void 0 ? void 0 : response.content) === null || _b === void 0 ? void 0 : _b.employment_details) === null || _c === void 0 ? void 0 : _c.employee_number);
                            setEmployer((_e = (_d = response === null || response === void 0 ? void 0 : response.content) === null || _d === void 0 ? void 0 : _d.employment_details) === null || _e === void 0 ? void 0 : _e.employer);
                            setWorkAddress((_g = (_f = response === null || response === void 0 ? void 0 : response.content) === null || _f === void 0 ? void 0 : _f.employment_details) === null || _g === void 0 ? void 0 : _g.address);
                            setSupervisor((_j = (_h = response === null || response === void 0 ? void 0 : response.content) === null || _h === void 0 ? void 0 : _h.employment_details) === null || _j === void 0 ? void 0 : _j.supervisor);
                            setDepartmentNumber((_l = (_k = response === null || response === void 0 ? void 0 : response.content) === null || _k === void 0 ? void 0 : _k.employment_details) === null || _l === void 0 ? void 0 : _l.department);
                            _a = setWorkNumber;
                            return [4 /*yield*/, (0, functions_1.convert_number)((_o = (_m = response === null || response === void 0 ? void 0 : response.content) === null || _m === void 0 ? void 0 : _m.employment_details) === null || _o === void 0 ? void 0 : _o.work_number)];
                        case 1:
                            _a.apply(void 0, [_s.sent()]);
                            start_date = moment.unix((_r = (_q = (_p = response === null || response === void 0 ? void 0 : response.content) === null || _p === void 0 ? void 0 : _p.employment_details) === null || _q === void 0 ? void 0 : _q.appointment_date) === null || _r === void 0 ? void 0 : _r.seconds);
                            setStartDate(start_date);
                            setIsLoadingData(false);
                            return [3 /*break*/, 3];
                        case 2:
                            dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.message] }));
                            setIsLoadingData(false);
                            _s.label = 3;
                        case 3: return [2 /*return*/];
                    }
                });
            }); });
        }
        //
    }, [user]);
    var saveEmploymentDetails = function () { return __awaiter(void 0, void 0, void 0, function () {
        var newDate, employment_details, employmentDetails;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!validateDetails()) return [3 /*break*/, 3];
                    setIsSaving(true);
                    newDate = startDate;
                    employment_details = {
                        date_updated: new Date(),
                        address: workAddress,
                        appointment_date: new Date(newDate),
                        department: departmentNumber,
                        employee_number: employeeNumber,
                        employer: employer,
                        supervisor: supervisor,
                        work_number: workNumber,
                    };
                    return [4 /*yield*/, (0, update_advanced_user_details_1.update_advanced_user_details)({
                            employment_details: employment_details,
                        })];
                case 1:
                    employmentDetails = _a.sent();
                    if (!(employmentDetails === null || employmentDetails === void 0 ? void 0 : employmentDetails.success)) return [3 /*break*/, 3];
                    //Update Loan Details
                    return [4 /*yield*/, (0, application_1.update_loan_application)({
                            uid: loan_id,
                            employment_details: employment_details,
                            employment_complete: true,
                        }).then(function (response) {
                            if (response.success) {
                                setIsSaving(false);
                                window.scrollTo({
                                    top: 0,
                                    behavior: "smooth",
                                });
                                navigate("".concat(route_names_1.ROUTE_LOAN_APPLICATION, "/").concat(steps_1.APP_STEP_PAYPOINT_DETAILS, "/").concat(loan_id));
                                //navigate next
                            }
                            else {
                                dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.message] }));
                            }
                        })];
                case 2:
                    //Update Loan Details
                    _a.sent();
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var previousStep = function () {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        navigate("".concat(route_names_1.ROUTE_LOAN_APPLICATION, "/").concat(steps_1.APP_STEP_FAMILY_DETAILS, "/").concat(loan_id));
    };
    var validateDetails = function () {
        var messageCount = 0;
        if (!employer) {
            messageCount++;
            setEmployerMessage("Employer is required.");
        }
        if (!startDate) {
            messageCount++;
            setStartDateMessage("Start date is required.");
        }
        if (!departmentNumber) {
            messageCount++;
            setDepartmentNumberMessage("Department number is required.");
        }
        if (!employeeNumber) {
            messageCount++;
            setEmployeeNumberMessage("Employee number is required.");
        }
        if (!workNumber.phone_number) {
            messageCount++;
            setWorkNumberMessage("Work Number is required.");
        }
        if (!workAddress) {
            messageCount++;
            setWorkAddressMessage("Work address is required.");
        }
        if (!supervisor) {
            messageCount++;
            setSupervisorMessage("Supervisor is required.");
        }
        return messageCount ? false : true;
    };
    return (react_1.default.createElement("div", { className: "flex flex-col gap-8" },
        react_1.default.createElement(doshx_controls_web_1.ScreenContainerControl, { className: "flex flex-col gap-8" },
            react_1.default.createElement(page_path_1.PagePath, { path: [
                    {
                        page: "/",
                        title: "Home",
                    },
                    {
                        page: "#",
                        title: "Loan Application",
                    },
                    {
                        title: "Employment Details",
                        active: true,
                    },
                ] }),
            isLoadingData ? (react_1.default.createElement(doshx_controls_web_1.ShimmerBoxControl, null)) : (react_1.default.createElement("div", { className: "flex flex-col  gap-4" },
                react_1.default.createElement("div", { className: "" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Employment Details", textColor: "text-black", bold: true })),
                react_1.default.createElement("div", { className: "flex flex-col  gap-4" },
                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Employer", value: employer, size: "small", errorText: employerMessage, onChange: function (v) { return setEmployer(v); } }),
                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Your Physical Workplace Address", value: workAddress, size: "small", errorText: workAddressMessage, onChange: function (v) { return setWorkAddress(v); } }),
                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Name of Supervisor", value: supervisor, size: "small", errorText: supervisorMessage, onChange: function (v) { return setSupervisor(v); } }),
                    react_1.default.createElement(doshx_controls_web_1.PhoneInputControl, { label: "Work Number", value: workNumber === null || workNumber === void 0 ? void 0 : workNumber.phone_number, size: "small", errorText: workNumberMessage, defaultCountryCode: (_a = workNumber.country_abbreviation) !== null && _a !== void 0 ? _a : "NA", showExtension: true, showFlag: true, onChange: function (v) {
                            return setWorkNumber(__assign(__assign({}, workNumber), { phone_number: v }));
                        }, onExtensionChange: function (ext, code) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                setWorkNumber({
                                    phone_number: workNumber === null || workNumber === void 0 ? void 0 : workNumber.phone_number,
                                    country_code: ext,
                                    country_abbreviation: code,
                                });
                                return [2 /*return*/];
                            });
                        }); }, international: true }),
                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Department Number", value: departmentNumber, size: "small", errorText: departmentNumberMessage, onChange: function (v) { return setDepartmentNumber(v); } })),
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Employee Number", value: employeeNumber, size: "small", errorText: employeeNumberMessage, onChange: function (v) { return setEmployeeNumber(v); } }),
                react_1.default.createElement(doshx_controls_web_1.DatePickerControl, { label: "Start Date", value: startDate, inputFormat: "DD/MM/YYYY", size: "small", errorText: startDateMessage, onChange: function (v) {
                        setStartDate(v);
                    } }),
                react_1.default.createElement("div", { className: "flex justify-center gap-4 " },
                    react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Back", loading: isSaving, backgroundColor: "bg-primary", size: "small", startIcon: pro_solid_svg_icons_1.faArrowLeft, onClick: function () {
                            previousStep();
                        } }),
                    react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Save & Proceed", backgroundColor: "bg-secondary", size: "small", loading: isSaving, endIcon: pro_solid_svg_icons_1.faArrowRight, onClick: function () {
                            saveEmploymentDetails();
                        } })))))));
};
exports.EmploymentDataPage = EmploymentDataPage;
